export function intersect(a, b) {
	const result = [];
	for (let i = 0; i < a.length; i++) {
		for (let j = 0; j < b.length; j++) {
			if (a[i] === b[j]) {
				result.push(a[i]);
				break;
			}
		}
	}
	return result;
}
