import InteractiveField from './interactive-field';
import textFieldMixin from './text-field-mixin';


class TextField extends textFieldMixin(InteractiveField) {

}


export default TextField;
