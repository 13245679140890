import PageComponent from '../component/page-component';


class Slide extends PageComponent {

    constructor({
		root,
		element,
		type = '',
		autoload = false,
		loadedClass = 'loaded',
		defaultDuration = 1,
	}) {
		super({root: root, element: element});
		this.type = type;
		this.defaults.autoload = autoload;
		this.defaults.loadedClass = loadedClass;
		this.defaultDuration = defaultDuration;
		this.slideshow = null;
		this.index = null;
		this.current = false;
		this.playing = false;
		this.loading = false;
		this.loaded = false;
    }


    prepare() {
		for (const node of this.element.querySelectorAll('a, img')) {
			node.setAttribute('draggable', 'false');
		}
		this.openAsyncEvent('load');
		if (this.dataAttr().get('autoload') === true) {
			this.load();
		}
    }


	start(first) {
		if (this.current) {
			this.play();
		}
	}


	stop() {
		this.pause();
	}


    load() {
		if (!this.loading && !this.loaded) {
			this.loading = true;
			this.loaded = true;
			this.classList().add(this.dataAttr().get('loadedClass'));
			this.closeAsyncEvent('load');
			if (this.element) {
				this.events.trigger(this.element, 'slide:load', {component: this});
			}
			this.loading = false;
		}
        return this.on('load');
    }


    getDuration() {
        return this.dataAttr().get('duration', this.defaultDuration);
    }


    setDuration(value) {
        this.dataAttr().set('duration', value);
        return this;
	}


	setDefaultDuration(value) {
		this.defaultDuration = value;
		return this;
	}


    getIndex() {
        return this.index;
	}


	setIndex(value) {
		this.index = value;
		return this;
	}


	getSlideshow() {
		return this.slideshow;
	}


	setSlideshow(slideshow) {
		this.slideshow = slideshow;
		return this;
	}


	beforeSetCurrent() {
		return Promise.resolve();
	}


    setCurrent(value = true) {
		if (this.current !== value) {
			this.current = value;
			if (this.element) {
				this.events.trigger(this.element, 'slide:current', {component: this});
				if (value) {
					this.load();
				}
				const method = value ? 'play' : 'pause';
				this[method]();
			}
		}
        return this;
    }


    isCurrent() {
		return this.current;
    }


	getType() {
		return this.type;
	}


    play() {
		if (!this.playing) {
			this.playing = true;
		}
        return this;
    }


    pause() {
		if (this.playing) {
			this.playing = false;
		}
        return this;
    }

}

export default Slide;
