import Context from '../../common/context/context';


class ModalContext extends Context {

	constructor({name, root, popOnKey = 'esc', fixedClass = 'fixed', activeClass = 'active'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
	}


	getLevel() {
		return 1;
	}


	beforeSwitchIn(otherContext) {
		return otherContext;
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.linkListener = this.events.on(this.root, 'a', 'click', this.onLinkClick.bind(this));
		this.clickListener = this.events.on(this.element, 'click', this.onClickIn.bind(this));
		this.threeStateTransition(element).add(this.activeClass);
		window.scrollTo(0, 0);
		return otherContext;
	}


	switchOut(otherContext) {
		const element = this.getElement();
		this.classList(element).add(this.fixedClass);
		if (this.linkListener) {
			this.linkListener.destroy();
		}
		if (this.clickListener) {
			this.clickListener.destroy();
		}
		this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			return otherContext;
		});
		return otherContext;
	}


	onLinkClick(event, target) {
		if (this.contexts.getCurrentContext() === this) {
			const href = target.getAttribute('href');
			if (href.charAt(0) !== '#' || !this.getElement().querySelector(href)) {
				event.preventDefault();
				this.contexts.pop().then(() => {
					target.click();
				});
			}
		}
	}


	onClickIn(event, target) {
		if (this.contexts.getCurrentContext() === this) {
			if (event.target === this.getElement()) {
				// click inside the element but outside the content
				this.contexts.pop();
			}
		}
	}


}


export default ModalContext;
