import BaseField from './base-field';
import interactiveFieldMixin from './interactive-field-mixin';

class InteractiveField extends interactiveFieldMixin(BaseField) {

	constructor({
		root,
		element,
		eventsEnabled = true,
		asyncEvents = true,
		invalidClass = 'invalid',
		name,
		type,
		errorTpl
	}) {
		super({root: root, element: element});
		this.name = name;
		this.type = type;
		this.eventsEnabled = eventsEnabled;
		this.asyncEvents = asyncEvents;
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
	}


	prepare() {
		this.prepareInteractiveField({
			eventsEnabled: this.eventsEnabled,
			asyncEvents: this.asyncEvents
		});
		return super.prepare();
	}

}


export default InteractiveField;
