import formConstants from './_constants';


const discoverFieldsMixin = (Base = class Empty {}) => class extends Base {

	discoverFields(element) {
		const fieldElements = element.querySelectorAll(this.dataSelector(formConstants.fieldSelectorAttribute));
		for (const fieldElement of fieldElements) {
			if (fieldElement.parentNode.closest(this.dataSelector(formConstants.groupAttribute)) === element) {
				this.addField(this.getComponent(fieldElement));
			}
		}
	}

};


export default discoverFieldsMixin;
