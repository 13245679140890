import PageComponent from '../component/page-component';


class SearchResults extends PageComponent {


	processResults(results) {
		return Promise.resolve(results);
	}


	reset() {}

}


export default SearchResults;
