import httpRequest from 'superagent';
import {isString, seemsPlainObject} from '../utils/types';
import PageComponent from '../component/page-component';
import fieldsGroupMixin from './fields-group-mixin';
import discoverFieldsMixin from './discover-fields-mixin';

const defaultValues = {
	autoAddFields: true,
	errorTemplateAttrName: 'errorTemplate',
	messageTemplateAttrName: 'messageTemplate',
	messageTypeAttrName: 'messageType',
	messagesAttrName: 'messages'

};



class Form extends discoverFieldsMixin(fieldsGroupMixin(PageComponent)) {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		super({root: root, element: element});
		this.defaults = Object.assign({}, defaultValues, defaults);
		this.autoAddFields = this.defaults.autoAddFields;
		this.submitEnabled = true;
	}



	prepare() {
		this.options = this.dataAttr().getAll();
		this.errorTemplate = this.element.querySelector(this.dataSelector(this.options.errorTemplateAttrName));
		this.messageTemplate = this.element.querySelector(this.dataSelector(this.options.messageTemplateAttrName));
		this.messages = this.element.querySelector(this.dataSelector(this.options.messagesAttrName));
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this));
		if (this.autoAddFields) {
			this.discoverFields(this.element);
		}
	}


	focus() {
		if (this.fields) {
			for (const field of this.fields.values()) {
				if (field.canFocus()) {
					field.focus();
					break;
				}
			}
		}
		return this;
	}


	disableSubmit() {
		this.submitEnabled = false;
		return this;
	}


	enableSubmit() {
		this.submitEnabled = true;
		return this;
	}


	async onSubmit(event) {
		event.preventDefault();
		if (
			document.activeElement &&
			document.activeElement.tagName.toLowerCase() === 'button' &&
			document.activeElement.hasAttribute('type') &&
			document.activeElement.getAttribute('type') === 'submit'
		) {
			document.activeElement.blur();
		}
		if (this.submitEnabled) {
			const submitEvent = this.events.trigger(this.element, 'form:submit', {wrapper: this});
			if (!submitEvent.defaultPrevented) {
				if (this.element.hasAttribute('action')) {
					this.disableSubmit();
					const action = this.element.getAttribute('action');
					const method = (this.element.hasAttribute('method') ? this.element.getAttribute('method') : 'post');
					const params = {
						values: this.getValue(),
						params: this.dataAttr().get('extraParams', {})
					};
					try {
						const response = await httpRequest[method](action)
							.set('X-Requested-With', 'XMLHttpRequest')
							.set('Accept', 'application/json')
							.send(params)
						;
						this.messages.innerHTML = '';
						if (response.ok) {
							const body = response.body;
							if (body.status === 'error' && ('formData' in body.output)) {
								const data = this.prepareErrors(body.output.formData);
								this.setValueAndErrors(data);
							} else {
								this.resetErrors();
							}
							if ('messages' in body && body.messages.length) {
								const messageTypeAttrName = this.options.messageTypeAttrName;
								const fragment = document.createDocumentFragment();
								for (const message of body.messages) {
									const messageFragment = this.messageTemplate.content.cloneNode(true);
									const messageNode = messageFragment.querySelector(this.dataSelector(messageTypeAttrName));
									if (isString(message)) {
										messageNode.textContent = message;
									} else {
										messageNode.textContent = message.message;
										this.dataAttr(messageNode).set(messageTypeAttrName, message.type);
									}
									fragment.appendChild(messageFragment);
								}
								this.messages.appendChild(fragment);
							}
						}
					} catch (error) {
						console.log('submit error', error);
					} finally {
						this.enableSubmit();
					}
				}
			}
		}
	}


	prepareErrors(data) {
		const prepareErrors = (entries) => {
			if (Array.isArray(entries)) {
				return entries.map((entry) => prepareErrors(entry));
			}
			if (seemsPlainObject(entries)) {
				for (const key of Object.keys(entries)) {
					if (key === 'errors') {
						entries.errors = entries.errors.map((error) => {
							const fragment = this.errorTemplate.content.cloneNode(true);
							fragment.querySelector(this.dataSelector('error')).innerHTML = error;
							return fragment;
						});
					} else {
						entries[key] = prepareErrors(entries[key]);
					}
				}
			}
			return entries;
		};
		return prepareErrors(data);
	}

}


export default Form;
