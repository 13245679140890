import Trackers from './trackers';
import DummyTracker from './dummy-tracker';
import GaGtagTracker from './ga-gtag-tracker';
import Tracker from './tracker';
// import PiwikNavigationTracker from './piwik-navigation-tracker';
// import GoogleTagManagerNavigationTracker from './google-tag-manager-navigation-tracker';
// import FbPixelNavigationTracker from './fb-pixel-navigation-tracker';


export default (di) => {
	di
		.setType({type: DummyTracker, name: 'DummyTracker', parent: 'Tracker', params: {name: 'dummy'}})
		.setType({type: GaGtagTracker, name: 'GaGtagTracker', parent: 'Tracker', params: {name: 'gaGtag'}})
		.setType({type: Tracker, name: 'Tracker', mixins: ['domMixin'], initCall: 'init'})
		.setType({type: Trackers, name: 'Trackers', parent: 'PageComponent'})

		// .setType({type: PiwikNavigationTracker, name: 'PiwikNavigationTracker', parent: 'NavigationTracker'})

		// .setType({type: GoogleTagManagerNavigationTracker, name: 'GoogleTagManagerNavigationTracker', parent: 'NavigationTracker'})

		// .setType({type: FbPixelNavigationTracker, name: 'FbPixelNavigationTracker', parent: 'NavigationTracker', params: {
		// 		trackEventEnabled: true,
		// 		clickTrackEnabled: true,
		// 		clickTrackAttribute: di.lazyCall(() => di.getValue('dom/dataAttrPrefix') + 'fb-click-track')
		// 	}
		// })

	;
};
