import Search from './search';
import SearchEngine from './search-engine';
// import ApiSearchEngine from './api-search-engine';
import BasicSearchEngine from './basic-search-engine';
import SearchResults from './search-results';
import ServerSideSearchResults from './server-side-search-results';
// import SearchPageTransition from './search-page-transition';
// import TagsVisibilityToggler from './tags-visibility-toggler';

const config = (di) => {
	di
		.setType({type: Search, name: 'Search', parent: 'PageComponent', setters: {
				injectHistory: di.lazyGet('navigation/history'),
				injectSearchEngineFactory: di.lazyNew('Factory', {suffix: 'SearchEngine'}),
				// injectSearchEngine: di.lazyGet('search/engine'),
			}
		})

		// .setType({type: TagsVisibilityToggler, name: 'TagsVisibilityToggler', parent: 'PageComponent'})

		// .setType({type: SearchPageTransition, name: 'SearchPageTransition', parent: 'PageTransition'})

		.setType({type: SearchEngine, name: 'SearchEngine'})

		// .setType({type: ApiSearchEngine, name: 'ApiSearchEngine', parent: 'SearchEngine', params: {
		// 		api: di.lazyGet('api/api')
		// 	}
		// })
		// .set('search/engine', di.lazyNew('ApiSearchEngine'))

		.setType({type: BasicSearchEngine, name: 'BasicSearchEngine', parent: 'SearchEngine', params: {
				searchUrl: di.lazyValue('search/searchUrl', ''),
				trackUrl: di.lazyValue('search/trackUrl', ''),
			}
		})
		.set('search/engine', di.lazyNew('BasicSearchEngine'))

		.setType({type: SearchResults, name: 'SearchResults', parent: 'PageComponent'})
		.setType({type: ServerSideSearchResults, name: 'ServerSideSearchResults', parent: 'SearchResults'})

	;
};


export default config;
