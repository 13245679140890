import Slide from './slide';


class ImageSlide extends Slide {

    constructor({
		root,
		element,
		autoload = false,
		loadedClass = 'loaded',
		defaultDuration = 1,
		slideImageAttribute = 'slideImage'
	}) {
		super({root: root, element: element, type: 'image', autoload: autoload, loadedClass: loadedClass, defaultDuration: defaultDuration});
		this.image = null;
		this.slideImageAttribute = slideImageAttribute;
    }


    getImage() {
		if (!this.image) {
			if (this.slideImageAttribute) {
				this.image = this.getComponent(this.element.querySelector(this.dataSelector(this.slideImageAttribute)));
			}
			if (!this.image) {
				this.image = this.getComponent('AsyncPicture');
			}
		}
		return this.image;
	}


    load() {
		const image = this.getImage();
		if (!image) {
			return super.load();
		}
		if (!this.loading && !this.loaded) {
			this.loading = true;
			image.load().then(() => {
				this.loaded = true;
				this.classList().add(this.dataAttr().get('loadedClass'));
				this.closeAsyncEvent('load');
				if (this.element) {
					this.events.trigger(this.element, 'slide:load', {component: this});
				}
				this.loading = false;
			});
		}
        return this.on('load');
    }


}

export default ImageSlide;
