import Slide from './slide';


class MediaSlide extends Slide {

    constructor({
		root,
		element,
		autoload = false,
		loadedClass = 'loaded',
		defaultDuration = 1,
		loadableComponentNames = ['AsyncPicture', 'VideoPlayer'],
		playableComponentNames = ['VideoPlayer'],
	}) {
		super({root: root, element: element, type: 'image', autoload: autoload, loadedClass: loadedClass, defaultDuration: defaultDuration});
		this.loadableComponentNames = loadableComponentNames;
		this.playableComponentNames = playableComponentNames;
		this.loadableComponents = null;
		this.playableComponents = null;
    }


	getLoadableComponents() {
		if (!this.loadableComponents) {
			this.loadableComponents = this.getNestedComponents(this.loadableComponentNames);
		}
		return this.loadableComponents;
	}


	getPlayableComponents() {
		if (!this.playableComponents) {
			this.playableComponents = this.getNestedComponents(this.playableComponentNames);
		}
		return this.playableComponents;
	}


	getNestedComponents(group) {
		const selectors = group.map((name) => this.dataSelector('component', name));
		if (selectors.length) {
			return this.components.queryComponents(this.element, selectors.join(','));
		}
		return [];
	}



    load() {
		const components = this.getLoadableComponents();
		if (!components.length) {
			return super.load();
		}
		if (!this.loading && !this.loaded) {
			this.loading = true;
			const promises = components.map((component) => component.load());
			Promise.all(promises).then(() => {
				this.loaded = true;
				this.classList().add(this.dataAttr().get('loadedClass'));
				this.closeAsyncEvent('load');
				if (this.element) {
					this.events.trigger(this.element, 'slide:load', {component: this});
				}
				this.loading = false;
			});
		}
        return this.on('load');
    }


    play() {
		if (!this.playing) {
			super.play();
			this.getPlayableComponents().map((component) => component.play());
		}
        return this;
    }


    pause() {
		if (this.playing) {
			super.pause();
			this.getPlayableComponents().map((component) => component.pause());
		}
        return this;
    }

}

export default MediaSlide;
