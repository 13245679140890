import PageComponent from '../component/page-component';

const diffVarName = '--mosaicHeightDiff';


class MosaicFixer extends PageComponent {

	prepare() {
		this.lastDiff = 0;
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.update();
	}


	onResize(event) {
		this.update();
	}


	update() {
		const height = this.element.clientHeight;
		let contentHeight = 0;
		for (const child of this.element.children) {
			contentHeight += child.clientHeight;
		}
		const diff = Math.max(0, height - contentHeight);
		if (this.dataAttr(this.element).get('index') === 13) {
			console.log(height, contentHeight, this.lastDiff, this.dataAttr(this.element).get('inLargeGroupPosition'), ' > ', this.dataAttr(this.element.nextElementSibling).get('inLargeGroupPosition'), this.element.nextElementSibling);
		}
		if (diff !== this.lastDiff && this.element.nextElementSibling) {
			this.element.nextElementSibling.style.setProperty(diffVarName, -diff + 'px');
			this.lastDiff = diff;
		}
	}

}

export default MosaicFixer;
