const fieldsGroupMixin = (Base = class Empty {}) => class extends Base {

	initFieldsCollection() {
		if (!this.fields) {
			if (this.isArray()) {
				this.fields = [];
			} else {
				this.fields = new Map();
			}
		}
	}


	clear() {
		super.clear();
		this.fields = null;
	}


	isArray() {
		return false;
	}


	addField(field) {
		this.initFieldsCollection();
		if (this.isArray()) {
			const name = this.fields.length;
			field.setName(name);
			this.fields[name] = field;
		} else {
			this.fields.set(String(field.getName()), field);
		}
		return this;
	}


	removeField(field, detach = true) {
		let name = field.getName();
		if (this.isArray()) {
			if (name < this.fields.length) {
				this.fields.splice(name, 1);
				for (let i = name, end = this.fields.length; i < end; i++) {
					this.fields[i].setName(i);
				}
			}
		} else {
			name = String(name);
			if (this.fields.has(name)) {
				this.fields.delete(name);
			}
		}
		if (detach) {
			field.getElement().parentNode.removeChild(field.getElement());
		}
		return this;
	}


	removeFieldByName(name) {
		if (this.hasField(name)) {
			return this.removeField(this.getField(name));
		}
		return this;
	}


	hasField(name) {
		this.initFieldsCollection();
		return this.isArray() ? (name >= 0 && name < this.fields.length) : this.fields.has(String(name));
	}


	getField(name) {
		this.initFieldsCollection();
		if (this.hasField(name)) {
			return this.isArray() ? this.fields[name] : this.fields.get(String(name));
		}
		throw new Error('Field ' + name + ' not defined');
	}


	getFields() {
		this.initFieldsCollection();
		return this.fields;
	}


	count() {
		return this.isArray() ? this.fields.length : this.fields.size;
	}


	getValue() {
		this.initFieldsCollection();
		let values;
		let iterator;
		if (this.isArray()) {
			values = [];
			iterator = this.fields;
		} else {
			values = {};
			iterator = this.fields.values();
		}
		for (const field of iterator) {
			values[field.getName()] = field.getValue();
		}
		return values;
	}


	setValue(value, resetErrors = false) {
		this.initFieldsCollection();
		const update = (name, newValue) => {
			if (this.hasField(name)) {
				const field = this.getField(name);
				field.setValue(newValue);
				if (resetErrors) {
					field.resetErrors();
				}
			}
		};

		if (Array.isArray(value)) {
			for (let name = 0, end = value.length; name < end; name++) {
				update(name, value[name]);
			}
			for (let i = value.length; i < this.fields.length; i++) {
				this.removeFieldByName(i);
			}
		} else {
			for (const name in value) {
				if (value.hasOwnProperty(name)) {
					update(name, value[name]);
				}
			}
		}
		return this;
	}


	resetValue() {
		const iterator = this.isArray() ? this.fields : this.fields.values();
		for (const field of iterator) {
			field.resetValue();
		}
		return this;
	}


	// setErrors(errors) {
	// 	this.initFieldsCollection();
	// 	const update = (name, newErrors) => {
	// 		if (this.hasField(name)) {
	// 			const field = this.getField(name);
	// 			field.setErrors(newErrors);
	// 		}
	// 	};

	// 	if (Array.isArray(errors)) {
	// 		for (let name = 0, end = errors.length; name < end; name++) {
	// 			update(name, errors[name]);
	// 		}
	// 	} else {
	// 		for (const name in errors) {
	// 			if (errors.hasOwnProperty(errors)) {
	// 				update(name, errors[name]);
	// 			}
	// 		}
	// 	}
	// 	return this;
	// }


	resetErrors() {
		const iterator = this.isArray() ? this.fields : this.fields.values();
		for (const field of iterator) {
			field.resetErrors();
		}
		if ('setOwnErrors' in this) {
			this.setOwnErrors([]);
		}
		return this;
	}


	setValueAndErrors(data) {
		const update = (name, newData) => {
			if (this.hasField(name)) {
				this.getField(name).setValueAndErrors(newData);
			}
		};
		if (Array.isArray(data.fields)) {
			for (let name = 0, end = this.fields.length; name < end; name++) {
				if (name < data.fields.length && data.fields[name]) {
					update(name, data.fields[name]);
				} else {
					this.fields[name].resetErrors();
				}
			}
		} else {
			for (const name of this.fields.keys()) {
				if (name in data.fields) {
					update(name, data.fields[name]);
				} else {
					this.getField(name).resetErrors();
				}
			}
		}
		if ('setOwnErrors' in this) {
			this.setOwnErrors('errors' in data ? data.errors : []);
		}
		return this;
	}


	reset() {
		this.resetValue();
		this.resetErrors();
		return this;
	}

};


export default fieldsGroupMixin;
