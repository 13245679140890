import httpRequest from 'superagent';
import SearchEngine from './search-engine';


class BasicSearchEngine extends SearchEngine {

	constructor({searchUrl = '', trackUrl = '', searchMethod = 'post', trackMethod = 'post', aliases = {}, supportingPagination = true} = {}) {
		super();
		this.searchUrl = searchUrl;
		this.trackUrl = trackUrl;
		this.searchMethod = searchMethod;
		this.trackMethod = trackMethod;
		this.aliases = aliases;
		this.supportingPagination = supportingPagination;
	}


	search(params) {
		const queryParams = {};
		for (const name in params) {
			if (params.hasOwnProperty(name)) {
				const key = (name in this.aliases ? this.aliases[name] : name);
				queryParams[key] = params[name];
			}
		}
		return new Promise((resolve, reject) => {
			const method = this.searchMethod;
			const remoteCall = httpRequest[method](this.searchUrl)
				.set('X-Requested-With', 'XMLHttpRequest')
				.set('Accept', 'application/json')
				.send(queryParams)
			;
			remoteCall.end((error, response) => {
				if (error || !response.ok) {
					reject(error);
				} else {
					const body = response.body;
					resolve(body);
				}
			});
		});
	}


	track(params) {
		if (this.trackUrl) {
			return new Promise((resolve, reject) => {
				const method = this.trackMethod;
				const remoteCall = httpRequest[method](this.trackUrl)
					.set('X-Requested-With', 'XMLHttpRequest')
					.set('Accept', 'application/json')
					.send(params)
				;
				remoteCall.end((error, response) => {
					if (error || !response.ok) {
						reject(error);
					} else {
						const body = response.body;
						resolve(body);
					}
				});
			});
		} else {
			return Promise.resolve();
		}
	}


	supportPagination() {
		return this.supportingPagination;
	}

}


export default BasicSearchEngine;
