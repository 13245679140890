import FilterableList from './filterable-list';
import {waitFrame} from '../utils/wait';


const defaultValues = {
	groupsAttr: 'groups',
};

//group example: {size: 3, positionAttr: 'inSmallGroupPosition', indexVar: 'mosaicSmallGroupIndex'}
// $smallGroupIndex = floor($index / $smallGroupSize);
// $largeGroupIndex = floor($index / $largeGroupSize);
// $inSmallGroupPosition = ($index % $smallGroupSize) + 1;
// $inLargeGroupPosition = ($index % $largeGroupSize) + 1;



class FilterableMosaicGrid extends FilterableList {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		defaults = Object.assign({}, defaultValues, defaults);
		super({root: root, element: element, defaults: defaults});
		this.groups = [];
	}


	prepare() {
		this.options = this.dataAttr().getAll();
		this.groups = this.dataAttr().get(this.options.groupsAttr, []);
		super.prepare();
	}


	updateDom() {
		let promise = super.updateDom();
		promise = promise
			.then(() => waitFrame())
			.then(() => {
				for (const item of this.items) {
					if (item.visible) {
						for (const element of item.elements) {
							if (this.dataAttr(element).has('component')) {
								const component = this.getComponent(element);
								if (component) {
									component.update();
								}
							}
						}
					}
				}
			});
		return promise;
	}


	updateItemDom(item, index) {
		const promise = super.updateItemDom(item, index);
		for (const group of this.groups) {
			const groupIndex = Math.floor(item.visibleIndex / group.size);
			const inGroupPosition = (item.visibleIndex % group.size) + 1;
			for (const element of item.elements) {
				const dataset = this.dataAttr(element);
				dataset.set(group.positionAttr, inGroupPosition);
				element.style.setProperty('--' + group.indexVar, groupIndex);
			}
		}
		return promise;
	}

}


export default FilterableMosaicGrid;
