import InteractiveField from './interactive-field';


class SelectField extends InteractiveField {

	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('select');
		}
		return this.input;
	}


	writeValue(value) {
		this.getInput().value = value;
	}


	readValue() {
		return this.getInput().value;
	}


	setFocus() {
		this.getInput().focus();
	}


	setBlur() {
		this.getInput().blur();
	}

	enableInput() {
		this.getInput().disabled = false;
	}


	disableInput() {
		this.getInput().disabled = true;
	}

}


export default SelectField;
