 import ServerFilterableList from './server-filterable-list';
 import TdpSearch from './tdp-search';


export default (di) => {
	di
		.setType({
			type: TdpSearch,
			name: 'TdpSearch',
			parent: 'Search'
		})
		.setType({
			type: ServerFilterableList,
			name: 'ServerFilterableList',
			parent: 'PageComponent',
			setters: {
				injectHistory: di.lazyGet('navigation/history')
			}
		})
	;
};
