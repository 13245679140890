import {isString} from '../utils/types';
import Tracker from './tracker';


class GaGtagTracker extends Tracker {

	getDriver() {
		if (!this.driver) {
			// eslint-disable-next-line piggyback/no-restricted-global-extend
			this.driver = ('gtag' in window ? window.gtag : null);
			if (this.driver) {
				// eslint-disable-next-line piggyback/no-restricted-global-extend
				this.trackingId = window.gtagTrackingId;
			}
		}
		return this.driver;
	}


	doTrack(url, title) {
		console.log('tracking (' + this.name + ')', url, title);
		this.driver('config', this.trackingId, {
			page_title: title,
			page_location: url
		});
		// gtag('config', 'GA_TRACKING_ID', {
		// 	'page_title' : 'homepage',
		// 	'page_path': '/home'
		//   });
	}


	doTrackEvent(category, action = null, name = null, value = null) {
		const values = {};
		if (action === null) {
			action = category;
		} else {
			if (!isString(action)) {
				if ('name' in action) {
					values.event_label = action.name;
					if ('value' in action) {
						values.value = action.value;
					}
				}
				action = action.action;
			} else {
				if (name !== null) {
					values.event_label = name;
					if (value !== null) {
						values.value = value;
					}
				}
			}
			values.event_category = category;
		}
		this.driver('event', action, values);
	}

}


export default GaGtagTracker;
