import PageComponent from '../component/page-component';


class VideoPlayerBg extends PageComponent {

	constructor({
		element,
		root,
		objectFit = 'cover',
		objectFitAttribute = 'objectFit',
		externalAttribute = 'external',
		autoplayAttribute = 'autoplay',
		startedClass = 'started',
		observerId = 'videoBg'
	} = {}) {
		super({element: element, root: root});
		this.objectFitAttribute = objectFitAttribute;
		this.externalAttribute = externalAttribute;
		this.autoplayAttribute = autoplayAttribute;
		this.startedClass = startedClass;
		this.defaults[objectFitAttribute] = objectFit;
		this.observerId = observerId;

		this.videoWidth = 0;
		this.videoHeight = 0;
		this.videoRatio = 0;
		this.started = false;
		this.playing = false;
		this.wasPlaying = false;
	}


	injectIntersectionObservers(intersectionObservers) {
		this.intersectionObservers = intersectionObservers;
	}


	prepare() {
		this.objectFit = this.dataAttr().get(this.objectFitAttribute);
		this.external = !!this.dataAttr().get(this.externalAttribute, false);
		this.player = this.getComponent('VideoPlayer');
		this.autoplay = !!this.dataAttr(this.player.getElement()).get(this.autoplayAttribute, false);

		// external videos using iframe do not support object fit like native <video>, so we need to fix the size manually
		if (this.external && this.objectFit === 'cover') {
			this.player.on('load').then(() => {
				Promise.all([this.player.getVideoWidth(), this.player.getVideoHeight()]).then((dimensions) => {
					this.videoWidth = dimensions[0];
					this.videoHeight = dimensions[1];
					if (this.videoHeight > 0) {
						this.videoRatio = this.videoWidth / this.videoHeight;
						this.updateSize();
					}
				});
			});
			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		}
		this.listeners.play = this.events.on(this.player.getElement(), 'video:playing', this.onPlay.bind(this));

		// if the video is set to autoplay we make it run only when is inside the viewport
		if (this.autoplay) {
			this.intersectionObservers.initObserver(this.observerId, 0, this.onIntersect.bind(this));
			this.intersectionObservers.observe(this.observerId, this.element);
		}
	}


	clear() {
		if (this.autoplay) {
			this.intersectionObservers.unobserve(this.observerId, this.element);
		}
	}


	start(first) {
		if (this.wasPlaying) {
			this.player.play();
		}
	}


	stop() {
		this.player.isPlaying().then((playing) => {
			this.wasPlaying = playing;
			if (playing) {
				this.player.pause();
			}
		});
	}



	onResize(event) {
		this.updateSize();
	}


	onIntersect(entries) {
		for (const entry of entries) {
			if (entry.target === this.element) {
				if (entry.isIntersecting) {
					console.log('play');
					this.player.play();
				} else {
					console.log('pause');
					this.player.pause();
				}
				break;
			}
		}
	}


	onPlay() {
		if (!this.started) {
			this.started = true;
			this.playing = true;
			this.classList().add(this.startedClass);
		}
	}


	updateSize() {
		if (this.external && this.videoRatio !== 0 && this.objectFit === 'cover') {
			const rect = this.element.getBoundingClientRect();
			const ratio = rect.width / rect.height;
			let newWidth;
			let newHeight;
			if (this.videoRatio > ratio) {
				newHeight = rect.height;
				newWidth = newHeight * this.videoRatio;
			} else {
				newWidth = rect.width;
				newHeight = newWidth / this.videoRatio;
			}
			const playerElement = this.player.getElement();
			playerElement.style.width = newWidth + 'px';
			playerElement.style.height = newHeight + 'px';
		}
	}

}


export default VideoPlayerBg;
