import imagesLoaded from 'imagesloaded';
import {waitCondition} from '../../common/utils/wait';
import PageComponent from '../component/page-component';


class AsyncPicture extends PageComponent {


	constructor({root, element, loadedClass = 'loaded', autoload = true}) {
		super({root: root, element: element});
		this.defaults.autoload = autoload;
		this.defaults.loadedClass = loadedClass;
		this.isImg = null; // will be a boolean after prepare
		this.loading = false;
	}


	prepare() {
		this.openAsyncEvent('load');
		this.isImg = this.element.tagName.toLowerCase() === 'img';
		this.img = this.isImg ? this.element : this.element.querySelector('img');
		if (this.dataAttr().get('autoload') === true) {
			this.load();
		}
	}


	load() {
		if (!this.loading) {
			this.loading = true;
			const nodes = this.isImg ? [this.element] : this.element.querySelectorAll(this.dataSelector('srcset'));
			for (const node of nodes) {
				const dataAttr = this.dataAttr(node);
				if (dataAttr.has('srcset')) {
					node.setAttribute('srcset', dataAttr.get('srcset'));
					dataAttr.remove('srcset');
					if (this.isImg && dataAttr.has('sizes')) {
						node.setAttribute('sizes', dataAttr.get('sizes'));
						dataAttr.remove('sizes');
					}
				}
			}
			const condition = () => (this.img.currentSrc && this.img.currentSrc.length);
			waitCondition(condition).then(() => {
				const img = new Image();
				img.src = this.img.currentSrc;
				imagesLoaded([img], () => {
					this.closeAsyncEvent('load');
					this.classList(this.element).add(this.dataAttr().get('loadedClass'));
					this.events.trigger(this.element, 'picture:load', {component: this});
				});
			});
		}
		return this.on('load');
	}


	getImg() {
		return this.img;
	}

}

export default AsyncPicture;
