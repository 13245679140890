import PageComponent from '../component/page-component';
import baseFieldMixin from './base-field-mixin';
import fieldsGroupMixin from './fields-group-mixin';
import discoverFieldsMixin from './discover-fields-mixin';
import formConstants from './_constants';


class GroupField extends discoverFieldsMixin(fieldsGroupMixin(baseFieldMixin((PageComponent)))) {

	constructor({
		root,
		element,
		autoAddFields = true,
		invalidClass = 'invalid',
		errorTpl
	}) {
		super({root: root, element: element});
		this.autoAddFields = autoAddFields;
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
	}


	prepare() {
		const data = this.dataAttr();
		this.name = data.get(formConstants.nameAttribute);
		this.type = data.get(formConstants.typeAttribute);
		if (this.autoAddFields) {
			this.discoverFields(this.element);
		}
		this.prepareBaseField({
			name: this.name,
			type: this.type,
			invalidClass: this.invalidClass,
			errorTpl: this.errorTpl
		});
	}


}


export default GroupField;
