import Tracker from './tracker';


class DummyTracker extends Tracker {

	getDriver() {
		if (!this.driver) {
			// eslint-disable-next-line piggyback/no-restricted-global-extend
			this.driver = ('dummyTracker' in window ? window.dummyTracker : null);
		}
		return this.driver;
	}


	doTrack(url, title) {
		this.driver(url, title);
	}

}


export default DummyTracker;
