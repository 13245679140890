import PageComponent from '../component/page-component';


class CustomSelect extends PageComponent {

	constructor({
		root,
		element,
		multi = false,
		changeEvent = 'select:change',
		emptyValue = '',
		resetAttrName = 'reset',
		disabledClass = 'disabled',
		toggledClass = 'toggled'
	}) {
		super({root: root, element: element});
		this.defaults.multi = multi;
		this.defaults.changeEvent = changeEvent;
		this.defaults.emptyValue = emptyValue;
		this.resetAttrName = resetAttrName;
		this.disabledClass = disabledClass;
		this.toggledClass = toggledClass;
		this.toggler = null;
		this.inputs = [];
		this.inputsMap = new Map();
		this.enabled = true;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.toggler = this.getComponent('ContextualMenuToggler');
		this.inputs = this.element.querySelectorAll('input');
		for (const input of this.inputs) {
			this.inputsMap.set(input.value, input);
		}
		this.multi = !!data.multi;
		this.changeEvent = data.changeEvent;
		this.emptyValue = data.emptyValue;
		this.enabled = !this.classList().contains(this.disabledClass);
		this.initialValue = this.getValue();

		this.listeners.change = this.events.on(this.element, 'input', 'change', this.onInputChange.bind(this));
		this.listeners.reset = this.events.on(this.element, this.dataSelector(this.resetAttrName), 'click', this.onResetClick.bind(this));
		this.listeners.toggle = this.events.on(this.toggler.getElement(), 'toggler:toggle', this.onTogglerChange.bind(this));

	}


	onInputChange(event) {
		this.triggerChangeEvent();
		if (!this.multi) {
			this.toggler.toggleMenu();
		}
	}


	onResetClick() {
		this.reset(true);
	}


	onTogglerChange(event) {
		this.classList().toggle(this.toggledClass, event.detail.toggled);
	}


	getValue() {
		let value;
		if (this.multi) {
			value = [];
			for (const input of this.inputs) {
				if (input.checked) {
					value.push(input.value);
				}
			}
		} else {
			value = this.emptyValue;
			const checkedInput = this.element.querySelector('input:checked');
			if (checkedInput) {
				value = checkedInput.value;
			}
		}
		return value;
	}


	setValue(value, triggerChangeEvent = true) {
		for (const input of this.inputs) {
			input.checked = (this.multi ? value.indexOf(input.value) >= 0 : value === input.value);
		}
		if (this.triggerChangeEvent) {
			this.triggerChangeEvent();
		}
	}


	reset(triggerChangeEvent = true) {
		this.setValue(this.initialValue);
		if (this.triggerChangeEvent) {
			this.triggerChangeEvent();
		}
	}


	hasValue() {
		const value = this.getValue();
		return ((this.multi && value.length) || (!this.multi && value !== this.emptyValue));
	}


	getInputs() {
		return this.inputs;
	}


	getInputsMap() {
		return this.inputsMap;
	}


	isMulti() {
		return this.multi;
	}


	disable() {
		if (this.enabled) {
			this.enabled = false;
			this.classList().add(this.disabledClass);
			this.toggler.disable();
		}
	}


	enable() {
		if (!this.enabled) {
			this.enabled = true;
			this.classList().remove(this.disabledClass);
			this.toggler.enable();
		}
	}


	toggleEnable(value = null) {
		if (value === null) {
			value = !this.enabled;
		}
		const method = value ? 'enable' : 'disable';
		this[method]();
	}


	isEnabled() {
		return this.enabled;
	}


	triggerChangeEvent() {
		this.events.trigger(this.element, this.changeEvent, {value: this.getValue(), component: this});
	}

}

export default CustomSelect;
