import PageComponent from '../component/page-component';
import {intersect} from '../utils/array';
import {waitFrame} from '../utils/wait';

const defaultValues = {
	filterAttr: 'filter',
	itemAttr: 'index',
	indexesAttr: 'indexes',
	filterTagAttr: 'filterTag',
	readyClass: 'ready',
	hiddenClass: 'hidden',
	implicitCheckClass: 'implicitCheck',
	resetTagValue: 'reset',
	itemsAmountAttr: 'itemsAmount',
	textSearchType: 'textSearch',
	selectionType: 'selection',
	defaultType: 'selection',
	inputWaitTime: 250, // ms
};


class FilterableList extends PageComponent {

	constructor({root, element,	defaults = {}}) {
		super({root: root, element: element});
		this.defaults = Object.assign({}, defaultValues, defaults);
		this.filters = new Map();
		this.items = [];
		this.animationPromise = Promise.resolve();
		this.resetTag = null;
		this.itemsAmountElements = [];
		this.visibleAmount = 0;
		this.filtering = false;

		this.inputWaitTimeout = null;
	}


	prepare() {
		this.options = this.dataAttr().getAll();
		this.prepareFilters();
		this.prepareItems();
		const opts = this.options;

		this.listeners.filterChange = this.events.on(this.element, this.dataSelector(opts.filterAttr), 'select:change', this.onFilterChange.bind(this));
		this.listeners.tagClick = this.events.on(this.element, this.dataSelector(opts.filterTagAttr), 'click', this.onFilterTagClick.bind(this));

		this.update();
		this.classList().add(opts.readyClass);
	}


	prepareFilters() {
		const opts = this.options;
		const filterTags = this.element.querySelectorAll(this.dataSelector(opts.filterTagAttr));
		const tags = {};
		for (const filterTag of filterTags) {
			const names = this.dataAttr(filterTag).get(opts.filterTagAttr);
			if (names === opts.resetTagValue) {
				this.resetTag = filterTag;
			} else {
				if (!(names[0] in tags)) {
					tags[names[0]] = {};
				}
				tags[names[0]][names[1]] = filterTag;
			}
		}
		// prepare filters structures
		const filterElements = this.element.querySelectorAll(this.dataSelector(opts.filterAttr));
		for (const filterElement of filterElements) {
			const filterData = this.dataAttr(filterElement);
			const name = filterData.get(opts.filterAttr);
			let entry = {
				name: name,
				element: filterElement,
				type: filterData.get('type', opts.defaultType),
				options: []
			};

			switch (entry.type) {
				case opts.textSearchType:
					entry.options = filterData.get('options');
					// filterData.set('options', '');
					this.listeners.search = this.events.on(filterElement, 'input', this.onTextSearchChange.bind(this));
				break;

				case opts.selectionType:
					const filterComponent = this.getComponent(filterElement);
					entry = Object.assign(entry, {
						component: filterComponent,
						allChecked: true,
						indexes: filterData.get(opts.indexesAttr)
					});
					let checkedAmount = 0;
					for (const input of filterComponent.getInputs()) {
						entry.options.push({
							input: input,
							enabled: !input.disabled,
							indexes: this.dataAttr(input).get(opts.indexesAttr),
							tag: tags[name][input.value],
							tagClassList: this.classList(tags[name][input.value])
						});
						checkedAmount += (input.checked && !input.disabled ? 1 : 0);
					}
					if (checkedAmount > 0) {
						this.filtering = true;
					}
					entry.allChecked = (checkedAmount === 0);
					this.classList(filterElement).toggle(opts.implicitCheckClass, checkedAmount === 0);
				break;
			}
			this.filters.set(name, entry);
		}

		this.itemsAmountElements = this.element.querySelectorAll(this.dataSelector(opts.itemsAmountAttr));
	}


	prepareItems() {
		const opts = this.options;
		const itemElements = this.element.querySelectorAll(this.dataSelector(opts.itemAttr));
		for (const itemElement of itemElements) {
			const data = this.dataAttr(itemElement);
			const index = data.get(opts.itemAttr);
			let entry;
			if (this.items[index]) {
				entry = this.items[index];
				entry.elements.push(itemElement);
				entry.classLists.push(this.classList(itemElement));
				entry.threeStateTransitions.push(this.threeStateTransition(itemElement));
			} else {
				entry = {
					index: index,
					visibleIndex: index,
					elements: [itemElement],
					classLists: [this.classList(itemElement)],
					threeStateTransitions: [this.threeStateTransition(itemElement)],
					visible: true,
					wasVisible: true
				};
				this.items[index] = entry;
			}
		}
		this.visibleAmount = this.items.length;
	}


	onTextSearchChange(event) {
		if (this.inputWaitTimeout) {
			clearTimeout(this.inputWaitTimeout);
		}
		this.inputWaitTimeout = setTimeout(() => this.update(), this.options.inputWaitTime);
	}


	onFilterChange(event) {
		this.update();
	}


	onFilterTagClick(event, target) {
		const opts = this.options;
		const names = this.dataAttr(target).get(opts.filterTagAttr);
		if (names === opts.resetTagValue) {
			for (const filter of this.filters.values()) {
				filter.component.setValue([]);
			}
			this.update();
		} else {
			const [filterName, optionValue] = names;
			const filter = this.filters.get(filterName);
			if (filter) {
				const inputsMap = filter.component.getInputsMap();
				const input = inputsMap.get(optionValue);
				if (input) {
					input.checked = false;
					this.update();
				}
			}

		}
	}


	update() {
		this.updateData();
		this.updateDom();
	}


	updateData() {
		const opts = this.options;
		const visibleIndexesByFilter = new Map();
		let visibleIndexes = null;
		for (const [filterName, filter] of this.filters) {
			let indexes = [];
			switch (filter.type) {
				case opts.textSearchType:
					const inputValue = filter.element.value.trim();
					const searchValue = ' ' + inputValue;
					for (const option of filter.options) {
						if (inputValue.length === 0 || option.text.indexOf(searchValue) >= 0) {
							indexes.push(option.index);
						}
					}
				break;

				// the visible items are obtained concatenating (logical OR) the arrays
				// of indexes of each checked option of the same filter, and then
				// intersecting (logical AND) the obtained arrays.
				case opts.selectionType:
					// update allChecked flags
					const value = filter.component.getValue();
					filter.allChecked = (value.length === 0);
					if (!filter.allChecked) {
						let enabledChecked = 0;
						for (const option of filter.options) {
							if (option.enabled && option.input.checked) {
								enabledChecked++;
							}
						}
						if (enabledChecked === 0) {
							filter.allChecked = true;
						}
					}

					for (const option of filter.options) {
						if (filter.allChecked || option.input.checked) {
							indexes = indexes.concat(option.indexes);
							if (filter.allChecked) {
								indexes = indexes.concat(filter.indexes);
							}
						}
					}
				break;
			}
			indexes = [...new Set(indexes)];
			visibleIndexesByFilter.set(filterName, indexes);
			if (visibleIndexes === null) {
				visibleIndexes = indexes;
			} else {
				visibleIndexes = intersect(visibleIndexes, indexes);
			}
		}

		const visibleIndexesByOtherFilters = new Map();
		for (const name of visibleIndexesByFilter.keys()) {
			for (const [filterName, indexes] of visibleIndexesByFilter) {
				if (name !== filterName) {
					let otherIndexes = visibleIndexesByOtherFilters.get(name);
					if (!otherIndexes) {
						otherIndexes = indexes;
					} else {
						otherIndexes = intersect(otherIndexes, indexes);
					}
					visibleIndexesByOtherFilters.set(name, otherIndexes);
				}
			}
		}

		for (const [filterName, filter] of this.filters) {
			if (filter.type === opts.selectionType) {
				for (const option of filter.options) {
					if (this.filters.size > 1) {
						const intersection = intersect(visibleIndexesByOtherFilters.get(filterName), option.indexes);
						// console.log('compare', filterName, option.indexes, visibleIndexesByOtherFilters.get(filterName), intersection);
						option.enabled = (intersection.length > 0);
					} else {
						option.enabled = true;
					}
				}
			}
		}

		this.visibleAmount = 0;
		if (visibleIndexes !== null) {
			this.updateItemsData(visibleIndexes);
		}
	}


	updateItemsData(visibleIndexes) {
		let visibleIndex = 0;
		for (let index = 0; index < this.items.length; index++) {
			const item = this.items[index];
			item.visible = (visibleIndexes.indexOf(item.index) >= 0);
			if (item.visible) {
				this.visibleAmount++;
				item.visibleIndex = visibleIndex;
				visibleIndex++;
			}
		}
	}


	updateDom() {
		this.animationPromise = this.animationPromise
			.then(() => waitFrame())
			.then(() => {
				this.updateFiltersDom();
				this.updateItemsAmount();
				const promises = this.items.map((item, index) => this.updateItemDom(item, index));
				return Promise.all(promises);
			});
		return this.animationPromise;
	}


	updateFiltersDom() {
		const opts = this.options;
		this.filtering = false;
		for (const filter of this.filters.values()) {
			if (filter.type === opts.selectionType) {
				this.classList(filter.element).toggle(opts.implicitCheckClass, filter.allChecked);
				for (const option of filter.options.values()) {
					if (!this.filtering && option.input.checked) {
						this.filtering = true;
					}
					option.input.disabled = !option.enabled;
					if (option.tag) {
						option.tag.disabled = !option.enabled;
						option.tagClassList.toggle(opts.hiddenClass, !option.input.checked);
					}
				}
			}
		}
	}

	updateItemsAmount() {
		for (const element of this.itemsAmountElements) {
			element.textContent = this.visibleAmount;
		}
		if (this.resetTag) {
			this.classList(this.resetTag).toggle(this.options.hiddenClass, !this.isFiltering());
		}
	}


	isFiltering() {
		return this.filtering;
	}


	updateItemDom(item, index) {
		let promise = Promise.resolve();
		const visible = item.visible;
		if (item.wasVisible !== visible) {
			promise = Promise.all(item.threeStateTransitions.map((tst) => (tst[!visible ? 'add' : 'remove'](this.options.hiddenClass))));
			item.wasVisible = visible;
		}
		return promise;
	}

}


export default FilterableList;
