import appConfig from '../../common/app/_config';
import componentConfig from '../../common/component/_config';
import contextConfig from '../../common/context/_config';
import diConfig from '../../common/di/_config';
import domConfig from '../../common/dom/_config';
import eventsConfig from '../../common/events/_config';
import filterableListConfig from '../../common/filterable-list/_config';
import formConfig from '../../common/form/_config';
import googleConfig from '../../common/google/_config';
import mediaConfig from '../../common/media/_config';
import navigationConfig from '../../common/navigation/_config';
import pageConfig from '../../common/page/_config';
import preloadConfig from '../../common/preload/_config';
import routerConfig from '../../common/router/_config';
import searchConfig from '../../common/search/_config';
import slideshowConfig from '../../common/slideshow/_config';
import trackersConfig from '../../common/trackers/_config';
import vimeoConfig from '../../common/vimeo/_config';
import widgetConfig from '../../common/widget/_config';


const config = (di) => {
	appConfig(di);
	componentConfig(di);
	contextConfig(di);
	diConfig(di);
	domConfig(di);
	eventsConfig(di);
	filterableListConfig(di);
	formConfig(di);
	googleConfig(di);
	mediaConfig(di);
	navigationConfig(di);
	pageConfig(di);
	preloadConfig(di);
	routerConfig(di);
	searchConfig(di);
	slideshowConfig(di);
	trackersConfig(di);
	vimeoConfig(di);
	widgetConfig(di);
};


export default config;
