import {camelize} from '../utils/string';


class DataAttr {

	constructor({parser, element = null, defaults = {}, prefix = undefined} = {}) {
		this.parser = parser;
		this.element = element;
		this.defaults = defaults;
		this.prefix = prefix;
	}


	set(name, value) {
		if (value !== undefined) {
			this.element.setAttribute(this.parser.getAttributeName(name, this.prefix), this.parser.encodeValue(value));
		} else {
			this.remove(name);
		}
		return this;
	}


	get(name, defaultValue = undefined) {
		const value = this.parser.decodeValue(this.getAttribute(this.element, this.parser.getAttributeName(name, this.prefix)));
		return (value !== undefined ? value : (name in this.defaults ? this.defaults[name] : defaultValue));
	}


	getRaw(name, defaultValue = undefined) {
		const value = this.getAttribute(this.element, this.parser.getAttributeName(name, this.prefix));
		return (value !== undefined ? value : (name in this.defaults ? this.defaults[name] : defaultValue));
	}


	has(name) {
		return this.element.hasAttribute(this.parser.getAttributeName(name, this.prefix)) || (name in this.defaults);
	}


	getAll() {
		const values = {};
		for (let i = 0, end = this.element.attributes.length; i < end; i++) {
			const attr = this.element.attributes[i];
			if (attr.name.indexOf('data-') === 0) {
				values[camelize(this.parser.removePrefix(attr.name.substr(5), this.prefix))] = this.parser.decodeValue(attr.value);
			}
		}
		return Object.assign({}, this.defaults, values);
	}


	setAll(values) {
		for (const name in values) {
			if (values.hasOwnProperty(name)) {
				this.set(name, values[name]);
			}
		}
		return this;
	}


	remove(name) {
		this.element.removeAttribute(this.parser.getAttributeName(name, this.prefix));
		return this;
	}


	getAttribute(element, name) {
		if (element.hasAttribute(name)) {
			return element.getAttribute(name);
		}
		return undefined;
	}

}


export default DataAttr;
