import queryString from 'query-string';
import httpRequest from 'superagent';
import PageComponent from '../../common/component/page-component';


const defaultValues = {
	fieldAttrName: 'field',
	itemsContainerAttrName: 'itemsContainer',
	baseUrl: '',
	params: {},
};


class ServerFilterableList extends PageComponent {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		super({root: root, element: element});
		this.defaults = Object.assign({}, defaultValues, defaults);
		this.promise = Promise.resolve();
	}


	injectHistory(history) {
		this.history = history;
	}


	prepare() {
		this.options = this.dataAttr().getAll();
		this.fields = this.components.queryComponents(this.element, this.dataSelector(this.options.fieldAttrName));
		this.itemsContainer = this.element.querySelector(this.dataSelector(this.options.itemsContainerAttrName));
		this.listeners.change = this.events.on(this.element, 'field:change', this.onFieldChange.bind(this));
	}


	onFieldChange(event) {
		this.submit();
	}


	submit() {
		this.promise = this.promise.then(() => {
			const opts = this.options;
			const params = {};
			for (const field of this.fields) {
				const value = field.getValue();
				if (value.length && value !== '*') {
					params[field.getName()] = value;
				}
			}
			return new Promise((resolve, reject) => {
				const remoteCall = httpRequest.get(opts.baseUrl)
					.query(Object.assign({}, params, opts.params))
					.set('X-Requested-With', 'XMLHttpRequest')
					.set('Accept', 'text/plain')
				;
				remoteCall.end((error, response) => {
					if (error || !response.ok) {
						reject(error);
					} else {
						this.itemsContainer.innerHTML = response.text;
						const qs = queryString.stringify(params);
						const url = opts.baseUrl + (qs.length ? '?' + qs : '');
						this.history.replace(url, {}, document.title);
						resolve();
					}
				});
			});
		}).catch(() => Promise.resolve());
	}



}


export default ServerFilterableList;
